/* eslint-disable camelcase */
import React, { FC, useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import {
  PageLayout,
  PageHeading,
  FAQ,
  GetInTouch,
  MemberStories,
} from 'components';
import { SlideUpContent } from 'components/animations';
import RightArrowIcon from 'assets/svg/arrow-right.svg';

const Story: FC = ({ data }: any) => {
  const {
    title,
    tagline,
    description,
    getInTouchTitle,
    seo,
    banner,
    faq: { title: faqTitle, tagline: faqTagline, faqs },
  } = data.strapiStoryPage;
  const [cardsToDisplay, setcardsToDisplay] = useState(9);
  const [loadMore, setLoadMore] = useState(false);
  const Limit = 6;
  const loadMoreButton = () => {
    const newIndex = cardsToDisplay + Limit;
    const newShowMore = newIndex < data.MemberStories?.edges.length - 1;
    setcardsToDisplay(newIndex);
    setLoadMore(newShowMore);
  };
  useEffect(() => {
    if (data.MemberStories?.edges.length > cardsToDisplay) {
      setLoadMore(true);
    } else {
      setLoadMore(false);
    }
  }, [data.MemberStories?.edges]);
  return (
    <PageLayout
      title={title}
      metaTitle={seo?.metaTitle ?? title}
      description={seo?.metaDescription}
      image={seo?.shareImage?.localFile?.publicURL}
      delaySec={0.3}
      showBrand={false}
      bannerTitle={banner?.title}
      bannerTagline={banner?.tagline}
      bannerImage={banner?.image}
      bannerButton={banner?.ctaButton}
      bannerPlaceholder={banner?.postcodePlaceholder}
      faqs={faqs}
    >
      <div className="container story-page">
        <PageHeading
          title={title}
          subtitle={tagline}
          description={description}
          level={1}
        />
        {getInTouchTitle && (
          <SlideUpContent className="story-page-content" delaySec={0.7}>
            <GetInTouch getInTouchCtaLabel={getInTouchTitle} />
          </SlideUpContent>
        )}

        <MemberStories
          memberstories={data.MemberStories}
          cardsToDisplay={cardsToDisplay}
        />
        <div className="story-page-load-more">
          {loadMore && (
            <>
              <button type="button" onClick={() => loadMoreButton()}>
                Load more
                <RightArrowIcon />
              </button>
            </>
          )}
        </div>
        <div className="page-heading">
          <div className="page-heading-title title-with-line"> {faqTitle}</div>
          <div className="row">
            <div className="col-lg-8">
              <div className="page-heading-content">
                {faqTagline && (
                  <div className="page-heading-tagline">{faqTagline}</div>
                )}
              </div>
            </div>
          </div>
        </div>
        <FAQ Faqs={faqs} />
      </div>
    </PageLayout>
  );
};

export default Story;
export const query = graphql`
  query StoryPageQuery {
    strapiStoryPage {
      title
      tagline
      slug
      description
      getInTouchTitle
      banner {
        ctaButton
        id
        postcodePlaceholder
        tagline
        title
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      faq {
        title
        tagline
        faqs {
          title
          id
          answer
        }
      }
      seo {
        metaDescription
        metaTitle
        shareImage {
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(width: 400, height: 400)
            }
          }
        }
      }
    }
    MemberStories: allStrapiStories(sort: { fields: sortDate, order: DESC }) {
      edges {
        node {
          sortDate
          id
          title
          description
          weightLoss
          starRating
          clientDetails
          videoimage {
            image {
              alternativeText
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 800, height: 500)
                }
                publicURL
              }
            }
            video {
              localFile {
                publicURL
              }
            }
          }
        }
      }
    }
  }
`;
